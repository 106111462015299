/* .hover-scale:hover {
    width: '480px';
    height: '340px';
} */

.imageScale {
    transition: transform .2s; /* Animation */
    /* width: 352px;
    height: 288px; */
    object-fit: cover;
}
  
.imageScale:hover {
    transform: scale(1.02); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport)*/
}


/* Investor css */

.styleDiv{
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .styleDiv{
        flex-direction: column;
        padding: 25px;
      }
      .inverstorImgStyle{
        padding: 70px 0 25px 1px !important;
      }
  }

  .inverstorImgStyle{
    padding: 0;
  }



  /* line container */

  .line-container {
    border-left: 6px solid rgb(240, 244, 240);
    height: 200px;
    position: relative;
    left: 10%;
    margin-left: -3px;
    top: 0;
  }

  .collaborator-txt{
    transform: translate(20%,140px);
    font-size: 25px;
    color: #fff;
  }

  .wrap-title{
   word-wrap: break-word;
   width: 70%;
  }
  



  