.privacy-container {
  
  margin: 0 auto;
  padding: 20px 100px;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 90px;
}
h1, h2, h3 {
  color: #fff;
}
ul {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .privacy-container{
    padding: 20px 35px;
  }
  
}