/* .hover-scale:hover {
    width: '480px';
    height: '340px';
} */

.hover-scale {
    transition: transform .2s; /* Animation */
    width: 440px;
    height: 300px;
}
  
.hover-scale:hover {
    transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}